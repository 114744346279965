import axios from "axios";
import * as yup from "yup";
import * as formik from "formik";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";

import { X_API_KEY } from "../../../constants";
import Loader from "../../../components/Loader";
import RiskcovryLogo from "../../../assets/images/RiskcovryLogo.png";

function Riskcovry() {
  const { Formik } = formik;
  const [loadedUrl, setLoadedUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const urls = new URLSearchParams(location.search);

  useEffect(() => {
    setLoadedUrl(window.location.href);
  }, []);

  const [mobileValue, setMobileValue] = useState("");
  const [modelNameValue, setModelNameValue] = useState("");
  const [brandNameValue, setBrandNameValue] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [customerNameValue, setCustomerNameValue] = useState("");
  const [base64InvoiceImage, setBase64InvoiceImage] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [uploadInvoiceError, setUploadInvoiceError] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const onInvoiceFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64InvoiceImage(reader.result);
      };
      reader.onerror = () => {
        setUploadInvoiceError(true);
      };
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.onerror = () => {
        setUploadError(true);
      };
    }
  };

  const schema = yup.object().shape({
    // username: yup.string().required(),
    // email: yup.string().required(),
    // mobile: yup.string().required(),
    // brand: yup.string().required(),
    // model: yup.string().required(),
    // invoicePhoto: yup.string().required(),
    // invoicePhoto: yup
    //   .mixed()
    //   .required("A file is required")
    //   .test("fileSize", "File size is too large", (value) => {
    //     return value && value.size <= 2000000; // 2MB
    //   })
    //   .test("fileType", "Unsupported File Format", (value) => {
    //     return (
    //       value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    //     );
    //   }),
    // devicePhoto: yup
    //   .mixed()
    //   .required("You need to provide a file")
    //   .test("fileSize", "The file is too large", (value) => {
    //     return value && value[0].sienter <= 2000000;
    //   })
    //   .test(
    //     "type",
    //     "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
    //     (value) => {
    //       return (
    //         value &&
    //         (value[0].type === "image/jpeg" ||
    //           value[0].type === "image/jpg" ||
    //           value[0].type === "image/png")
    //       );
    //     }
    //   ),
  });

  useEffect(() => {
    setMobileValue(urls.get("Mobile"));
    setModelNameValue(urls.get("ModelName"));
    setBrandNameValue(urls.get("BrandName"));
    setActivationCode(urls.get("ActivationCode"));
    setEmailValue(urls.get("Email"));
    setCustomerNameValue(urls.get("CustomerName"));
  }, []);

  const handleFormUpload = (values) => {
    if (base64Image === "") {
      toast.error("Please select device photo.");
    } else {
      uploadDetailsApi(values);
    }
    // setLoading(true);
    // const DATA = {
    //   activationCode: "9978191138A4",
    //   // activationCode: values?.activationCode,
    //   InvoiceImg: base64InvoiceImage,
    //   DeviceImg: base64Image,
    // };
    // console.log("DATA", DATA);

    // axios(
    //   "https://cors-anywhere.herokuapp.com/https://portal.acrossassist.com/api/partner/v1/apb/SaveRiskyCoverDetails",
    //   {
    //     method: "POST",
    //     data: DATA,
    //     headers: {
    //       "x-api-key": X_API_KEY,
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((res) => {
    //     console.log("35 ==== Res ====", res);
    //     toast.success("Form successfully submitted!");
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     // console.log("37 ==== Err ====", e);
    //     toast.error("Form not submitted. Please try again");
    //     setLoading(false);
    //   });
  };

  const uploadDetailsApi = (values) => {
    setLoading(true);
    const DATA = {
      // activationCode: "9978191138A4",
      activationCode: values?.activationCode,
      InvoiceImg: base64InvoiceImage,
      DeviceImg: base64Image,
    };
    console.log("DATA", DATA);

    axios(
      // "https://cors-anywhere.herokuapp.com/https://portal.acrossassist.com/api/partner/v1/apb/SaveRiskyCoverDetails",
      "https://portal.acrossassist.com/api/partner/v1/apb/SaveRiskyCoverDetails",
      {
        method: "POST",
        data: DATA,
        headers: {
          "x-api-key": X_API_KEY,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",

          // "Access-Control-Allow-Headers":
          //   "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          // "Access-Control-Allow-Methods": "OPTIONS,POST",
          // "Access-Control-Allow-Credentials": true,
          // // "X-Requested-With": "*",
          // "X-Requested-With": "XMLHttpRequest",
        },
      }
    )
      .then((res) => {
        console.log("35 ==== Res ====", res);
        if (res?.data?.statusCode === 200) {
          setBase64Image("");
          toast.success("Form successfully submitted!");
          setLoading(false);
        } else {
          toast.error("Please validate your form and Try again !");
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log("37 ==== Err ====", e);
        toast.error("Form not submitted. Please try again");
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="auth-wrapper text-center">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="auth-content">
            <Card
              className="borderless text-center"
              // class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
              style={{ borderRadius: 8 }}
            >
              <Card.Body>
                <div
                  className="mb-4 d-row"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ width: "100%" }} className="mt-2">
                    <img src={RiskcovryLogo} style={{ width: "100%" }} alt="" />
                  </div>
                </div>

                <Formik
                  validationSchema={schema}
                  initialValues={{
                    username: customerNameValue,
                    email: emailValue,
                    mobile: mobileValue,
                    brand: brandNameValue,
                    model: modelNameValue,
                    activationCode: activationCode,
                    invoicePhoto: "",
                    devicePhoto: "",
                    terms: false,
                  }}
                  enableReinitialize={true}
                  // validator={() => ({})}
                  onSubmit={handleFormUpload}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div>
                        <p style={{ color: "red", textAlign: "start" }}>
                          *Ensure the user name matches the device owner name.
                        </p>
                      </div>

                      {/* ****** Username Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Username
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Username"
                              aria-describedby="inputGroupPrepend"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              // isInvalid={!!errors.username}
                              readOnly
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      {/* ****** Email Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          // controlId="validationFormikEmail"
                          controlID="formGridEmail"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Email
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              // isInvalid={!!errors.email}
                              // errors={errors}
                              readOnly
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      {/* ****** Mobile Container ****** */}
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="formGridPhone">
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Mobile
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder="Mobile"
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            // isInvalid={!!errors.mobile}
                            readOnly
                          />
                        </Form.Group>
                      </Row>
                      {/* ****** Brand Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormik02"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Brand
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="brand"
                            value={values.brand}
                            onChange={handleChange}
                            readOnly
                            // isValid={touched.brand && !errors.brand}
                          />
                        </Form.Group>
                      </Row>
                      {/* ****** Model Container ****** */}
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Model
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Model"
                            name="model"
                            value={values.model}
                            onChange={handleChange}
                            readOnly
                          />
                        </Form.Group>
                      </Row>
                      {/* ****** Invoivce Photo Container ****** */}
                      <Row className="mb-3">
                        <div className="form-group mb-3">
                          <div
                            class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            <label className="form-label text-dark f-w-600">
                              Invoice
                            </label>
                          </div>

                          <input
                            type="file"
                            class="form-control"
                            onChange={onInvoiceFileChange}
                          />
                        </div>

                        <div>
                          <p style={{ color: "red", textAlign: "start" }}>
                            *Please dial *#06# on your phone and capture an
                            image of the screen displaying the IMEI number to
                            upload here.
                          </p>
                        </div>
                      </Row>
                      {/* ****** Device Photo Container ****** */}
                      <Row className="mb-3">
                        <div className="form-group mb-3">
                          <div
                            class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            <label>
                              Device <label style={{ color: "red" }}>*</label>
                            </label>
                          </div>

                          <input
                            type="file"
                            class="form-control"
                            name="devicePhoto"
                            onChange={onFileChange}
                          />
                          {errors.base64Image && touched.devicePhoto ? (
                            <p style={{ color: "red", textAlign: "left" }}>
                              {errors.devicePhoto}
                            </p>
                          ) : null}
                        </div>
                      </Row>
                      {/* ****** Button Container ****** */}
                      <Button className="w-100 mt-3" type="submit">
                        Upload
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Riskcovry;
// import React, { useState } from "react";
// import { Form, Button, ProgressBar } from "react-bootstrap";
// import axios from "axios";

// const Riskcovry = () => {
//   const [file, setFile] = useState(null);
//   const [uploadPercentage, setUploadPercentage] = useState(0);
//   console.log("404 ==== File ====", file);

//   const onFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const onFileUpload = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const res = await axios.post("/upload", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           setUploadPercentage(percentCompleted);
//         },
//       });

//       console.log("File uploaded successfully:", res.data);
//     } catch (err) {
//       console.error("Error uploading file:", err);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <Form onSubmit={onFileUpload}>
//         <Form.Group controlId="formFile">
//           <Form.Label>Upload File</Form.Label>
//           <Form.Control type="file" onChange={onFileChange} />
//         </Form.Group>

//         {uploadPercentage > 0 && (
//           <ProgressBar
//             now={uploadPercentage}
//             label={`${uploadPercentage}%`}
//             className="my-3"
//           />
//         )}

//         <Button variant="primary" type="submit">
//           Upload
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default Riskcovry;
